import React, {useState, useEffect, useContext} from 'react'
import api from '../services/api'

import { Button, Input, Checkbox, OffsetHeader, CopyBox, Wrapper} from '../components'
  import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
  import 'react-phone-number-input/style.css'
  import PhoneInput from 'react-phone-number-input'
  import { AuthContext } from '../context/AuthContext';

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const Login = () => {
    const { slug } = useParams()
    const [page, setPage] = useState(0)
    const [optIn, setOptIn] = useState(true)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [countryCode, setCountryCode] = useState('US')
    const [phoneError, setPhoneError] = useState(false)
    const [codeError, setCodeError] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const [code, setCode] = useState('')
    const [course, setCourse] = useState(null)
    const [lesson, setLesson] = useState(null)
    let [searchParams] = useSearchParams();

    const navigate = useNavigate()

    const auth = useContext(AuthContext)

    useEffect(()=>{
        if (auth.user){
            let organization = auth.user.organizations.find((org)=>org.id == searchParams.get('organization_id'));
            if (organization){
                auth.updateOrganization(organization, true)
            }
            if (!auth.user.member_initialized && page!==2){
                setPage(2);
            } else {
                loadLesson();
            }
        }
    }, [auth.user])
    
    let query = useQuery();

    const verify = () => {
        setPhoneError(false);
        api.verify({'phone_number': phone}).then((response)=>{
            if(response == "pending"){
                setPage(1);   
                setLoginError(false);             
            } else {
                setPhoneError(true);
            }
       }).catch(()=>{
            setPhoneError(true);
       })
    }

    const login = () => {
        setCodeError(false);
        api.cookie().then(()=>{
          api.loginMember({'phone_number': phone, code, 'country_code': countryCode,'organization_id': searchParams.get('organization_id')}).then((response)=>{
            if (response == "approved"){
                auth.loadUser();
            } else {
                setCodeError(true);
            }
           }).catch((data)=>{
             if (data.response.status == 404){
                setLoginError(true);
                setPage(0);
             } else {
                setCodeError(true);

             }
           })
        });
      }

    const loadLesson = () => {
        navigate('/course/' + slug + '/lesson/' + query.get('lesson')  + '/youth');
    }

    const update = () => {
        api.updateMember({'opted_in_messaging': optIn, name}).then(()=>{
            loadLesson();
        })
     }
    
    useEffect(()=>{
        api.getCourse(slug).then((course)=>{
            setCourse(course);  
        })
        api.getLesson(query.get('lesson')).then((lesson)=>{
            setLesson(lesson);  
        })
    },[])

    if (!course || !lesson){
        return null;
    }

    let theme = lesson.theme;
    return <Wrapper mode="mobile">
    <OffsetHeader mode="mobile" expanded={true}>
            <div class="flex items-center">
                <div class="flex-1">
                    <img class="mx-auto w-64" src={course.logo.url}/>
                </div>
            </div>
    </OffsetHeader>

        {page == 0  &&  <><CopyBox theme={theme}>
            <div class="mb-4">
                To get going, enter your phone number below and we'll get you connected!
            </div>
            <div class="mb-4">
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-4">
                Phone Number
                </label>
                <div className="mt-1">
                <PhoneInput
                    className="py-3 px-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 "
                    value={phone}
                    addInternationalOption = {false}
                    defaultCountry={"US"}
                    countries={["US", "CA", "MX"]}
                    onChange={setPhone}
                    onCountryChange={setCountryCode}
                />
                </div>
                {phoneError ? <div class="text-xs mt-2 italic">There was an error with your phone number. Please try again.</div>: null}
                {loginError ? <div class="text-xs mt-2 italic">There was an error with your verification. Please try again.</div>: null}

            </div>
            </CopyBox>
            <Button theme={theme} onClick={verify}>Text My Code</Button></>

        }
        
        {page == 1  && <><CopyBox theme={theme}>
            <div class="mb-4">
                Great! We've sent you a one time code. Please enter it below.
            </div>
             <Input autocomplete="one-time-code" placeholder="xxxxxx" name="code" label="Code" value={code} onChange={setCode}/>
             {codeError ? <div class="text-xs mt-2 italic">There was an error with your code. Please try again.</div>: null}

            </CopyBox>
            <Button theme={theme} onClick={login}>Continue</Button>
            </>
        }
        {page == 2  && <><CopyBox theme={theme}>
            <div class="mb-4">
                Awesome. Final question: what's your first name?
            </div>
                <Input placeholder="" name="first_name" label="First Name" onChange={(val)=>{setName(val);}}/>
                <Checkbox name="opt_in" label="Send me encouragement and resources via text message." value={optIn} onChange={()=>{setOptIn(!optIn)}} />
            </CopyBox>
            <Button onClick={update} theme={theme}>Let's Go!</Button>
            </>
        }
    </Wrapper>
}

export default Login