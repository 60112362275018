import React, {useState} from 'react'
import { Button, Header, Input, Select, CopyBox, ErrorMessage, Wrapper, OffsetHeader } from '../components'
import themes from '../constants/themes';
import * as Yup from "yup";
import { useForm } from "react-yup";
import api from '../services/api';
import { Link } from 'react-router-dom'

const schema = Yup.object({
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    email: Yup.string().email().required('Email is required.'),
    password: Yup.string().required('Password is required.').min(8).max(100).matches(
        /[@$!%*#?&]+/,
      'Need one special character'),
    organization: Yup.string().required('Organization name is required.'),
    attendance: Yup.string().required('Please contact Encircled for pricing at this level.').default('0_49'),
}).defined();

const costOptions = [
    {'value': '0_49', 'title': '0-49 Students', 'cost':15000}, 
    {'value': '50_99', 'title': '50-99 Students', 'cost': 20000},
    {'value': '100_149', 'title': '100-149 Students', 'cost': 25000},
    {'value': '150_199', 'title': '150-199 Students', 'cost': 30000},
    {'value': '200_249', 'title': '200-249 Students', 'cost': 35000},
    {'value': 'CONTACT', 'title': '250+ Students', 'cost': null}
];

const Registration = () => {
    const theme = themes['orange'];
    const [serverError, setServerError] = useState(false)
    const [cost, setCost] = useState(costOptions[0].cost)

    const {
        touched,
        errors,
        field,
        setValue,
        setTouched,
        createSubmitHandler,
      } = useForm({
        validationSchema: schema,
      });

      const handleSubmit = React.useMemo(() => {
        return createSubmitHandler(
            (values) => {
                setServerError(false);
                api.createOrganization({
                    organization: values.organization, 
                    attendance: values.attendance,
                    user: {
                       firstName: values.firstName,
                       lastName: values.lastName,
                       email: values.email,
                       password:  values.password
                    }}).then((resp)=>{
                        window.location = resp.checkout_url;
                    }).catch(()=>{
                        setServerError(true);
                    });
            });
    }, []);

    return <Wrapper mode="desktop">
         <OffsetHeader mode="desktop" expanded={true}>
                    <div class="flex items-center">
                        <div class="flex-1">
                            <img class="mr-4 w-64" src={require('../images/encircled.png')}/>
                        </div>
                        <div>
                        <div class="text-xs italic mb-2">Already registered?</div>
                            <Link to={"/leader/login"} class="mr-3"><Button theme={theme}>Login</Button></Link>
                        </div>
                    </div>
            </OffsetHeader>
            <CopyBox theme={theme}>
            The Group Journey frees Youth Pastors up to do the kind of ministry that can create lasting impact in teens launching into adulthood. Get started below.</CopyBox>

        <div class="flex flex-col md:flex-row">
            <div class="flex-3 order-2 md:order-1">
                <Header classes="mb-4" theme={theme}>Get Started</Header>
                <form onSubmit={handleSubmit}>

                <div class="mb-4">
                <CopyBox theme={theme}>
                    {serverError ? <div class="text-xs my-2 italic">There was an error. Please try again. If you have previously used your email with Encircled, you may need to use a different one.</div> : null}

                    <h2 class="uppercase font-bold text-sm mb-4 border-b border-gray-200 pb-2">Your Info</h2>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <Input name="firstName" label="First Name" field={field}/>
                            <ErrorMessage error={errors.firstName} touched={touched.firstName}/>
                        </div>
                        <div>
                            <Input name="lastName" label="Last Name" field={field}/>
                            <ErrorMessage error={errors.lastName} touched={touched.lastName}/>
                        </div>
                    </div>

                    <Input name="email" label="Email" field={field}/>
                    <ErrorMessage error={errors.email} touched={touched.email}/>

                    <Input type="password" name="password" label="Password" field={field}/>
                    <ErrorMessage error={errors.password} touched={touched.password}/>

                    <h2 class="uppercase font-bold text-sm mb-4 border-b border-gray-200 pb-2 mt-8">Organization</h2>
                    <Input name="organization" label="Name" field={field}/>
                    <ErrorMessage error={errors.organization} touched={touched.organization}/>
                     <Select onChange={(val)=>{
                         setCost(val.cost);
                         setValue('attendance', val.cost ? val.value : '')
                         setTouched('attendance');
                     }} label="Expected Number of Youth" name="attendance" options={costOptions}/>
                     <div class="mt-4 font-bold">
                     {cost ? 'Total: $' +  cost/100: <div><a class="underline" target ="_blank" href="https://www.encircled.org/contact">Contact us</a> more pricing at this level.</div>}
                     </div>
                     <ErrorMessage error={errors.attendance} touched={touched.attendance}/>

                </CopyBox>
                </div>
                    <Button theme={theme}>Next</Button>
                </form>
           </div>
           <div class="self-start order-1 md:order-2 flex-1">
            <div class="p-8 m-8 border border-orange-600">
                <div>FREE RESOURCE</div>
                <Header size="lg" classes="mb-4" theme={themes['black']}>Learn How to Connect to Your Youth</Header>
                <p class="mb-4" >It's hard to know where to start when connecting with youth. We'll help you find your way.</p>
                <Button theme={theme}><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSeUkCqyB2epwhsH1a8ZqQ4WwrNBWUDPzYQHMMNqqJ2u8fpTDQ/viewform">Download</a></Button>
            </div>
            <div class="p-8 border m-8 border-gray-500 border-dashed">
            <Header size="xl" classes="mb-4" theme={themes['black']}>What's included in our resource?</Header>
                
                <ul class="text-xl">
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/4sessions.png')}/> 4 Sessions of Materials</li>
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/outline.png')}/> Speaking Outlines with Slides</li>
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/videos.png')}/> Pre-Recorded Video Talks</li>
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/circle.png')}/> Guided Circle of Support Experience</li>
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/small-group.png')}/> Small Group Outlines</li>
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/small-group-activity.png')}/> Small Group Individual Activities</li>
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/social.png')}/> Social Media Posts</li>
                    <li class="mb-4 flex items-center"><img class="h-12 pr-4" src={require('../images/icons/mobile.png')}/> Mobile Interactive Experience</li>
                </ul>
            </div>
           </div>
        </div>
    
    </Wrapper>
}

export default Registration