import React, { useState, useEffect} from 'react'
import { Header, Select } from '../components'
import QRCode from "react-qr-code";
import { useParams, useSearchParams } from 'react-router-dom'
import api from '../services/api'
import { FaPrint } from "react-icons/fa";

const QR = () => {
    const [lesson, setLesson] = useState(null)
    const [mode, setMode] = useState('both')
    const [hideSelect, setHideSelect] = useState(false);
    let [searchParams] = useSearchParams();

    const { courseSlug, lessonSlug } = useParams()

    useEffect(()=>{
        api.getLesson(lessonSlug).then((lesson)=>{
            setLesson(lesson);  
            if (lesson.small_group_components.length == 0){    
                setHideSelect(true);
                setMode('youth');
            }
        })
    },[])

    if (!lesson){
        return null;
    }
    let youthPath = process.env.REACT_APP_APP_BASE + "/course/" + courseSlug + "/lesson/" + lessonSlug + "/youth?organization_id=" + searchParams.get('organization_id')
    let leaderPath = process.env.REACT_APP_APP_BASE + "/course/" + courseSlug + "/lesson/" + lessonSlug + "/small-group-leader"
    return <>
        <div class="flex flex-col h-screen">
            <div class="flex items-center">
                <img class="mr-4 w-32" src={lesson.icon.url}/>
                <div class="flex-1">
                    <Header size="3xl" theme={lesson.theme}>{lesson.title} Discussion</Header>
                    <h2 class="text-lg font-light">Session {lesson.number}</h2>
                </div>
                <div class="mr-4 print:hidden flex items-center">
                    {!hideSelect ? <Select onChange={(val)=>{setMode(val.value)}} label={null} name="audience" options={[
                        {'value': 'both', 'title': 'Both'}, 

                        {'value': 'leader', 'title': 'Small Group Leader Guide'}, 
                        {'value': 'youth', 'title': 'Individual Reflection'},
                    ]}/> : null}
                    <FaPrint onClick={()=>{window.print()}} class="h-6 w-6 ml-8 mr-4"/>
                </div>

            </div>
            <div class={"mb-16 w-full h-1 bg-gradient-to-r print:mb-0 " + lesson.theme.separator_classes}></div>
            <h3 class="italic text-3xl text-center print:mb-8">Scan QR Code to get started!</h3>
            <div class="flex-1 flex items-center justify-around print:block">
                {mode == 'leader' || mode== 'both' ? <div class="text-center">
                    <a class="mb-6 inline-block print:mb-2" href={leaderPath}>
                    <QRCode size="325" value={leaderPath} />   
                    </a>
                    <h3 class="font-display font-bold text-3xl print:mb-8">Small Group Leader Guide</h3>
                </div> : null}
                {mode == 'youth' || mode== 'both' ? <div class="text-center">
                    <a class="mb-6 inline-block print:mb-2" href={youthPath}>
                        <QRCode size="325" value={youthPath} />   
                    </a>
                    <h3 class="font-display font-bold text-3xl">Individual Reflection</h3>
                </div> : null}

            </div>
        </div>
    </>
}

export default QR